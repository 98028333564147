
import { Box,Flex,SimpleGrid} from "@chakra-ui/react";
import NoResults from "components/errorStates/NoResults";
import SomethingWrong from "components/errorStates/SomethingWrong";
import LazyLoader from "components/lazyLoader/LazyLoader";
import Pagination from "components/pagination/pagination";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiendpoints } from "utils/apiendpoints";
import { DECISION_DATE } from "utils/constants";
import service from "utils/service";
import { columnsDataComplex} from "views/admin/decisionRunLogs/variables/columnsData";
import MainTable from "./components/MainTable";
import { mapSelectedDecisions } from "redux/actions/infoAction";
import { localStorageKeys } from "utils/localStorageKeys";
import { useHistory, useLocation } from "react-router-dom";
import  FixedHeaderTop  from "./components/fixedHeaderDecisionRuns/FixedHeaderTop"

export default function DecisionRunLogs() {

  const { dateTypeFilter } = useSelector(state => state.infoReducer);

  const dispatch = useDispatch();

  const location = useLocation();

  const [height, setHeight] = useState(0);

  const [showFilter, setShowFilter] = useState(true)

  const { selectedDecisions } = useSelector(state => state.infoReducer)

  const [selectedDecisionIds, setSelectedDecisionIds] = useState([]);

  const [selectedValues, setSelectedValues] = useState([]);

  const [selectedOption, setSelectedOption] = useState(null);

  const [content, setContent] = useState({})

  const [loader, setLoader] = useState(false)

  const [error, setError] = useState(false)

  const [search, setSearch] = useState('');

  const [status, setStatus] = useState('');

  const resultsPerPageList = [10, 20, 30, 40, 50]

  const defaultResultsPerPage = 10

  const [totalPage, setTotalPage] = useState(null);

  const [totalCount, setTotalCount] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  const [resultsPerPage, setResultsPerPage] = useState(10);

  const [triggerFilter, setTriggerFilter] = useState("");

  const [previousPage, setPreviousPage] = useState(null);

  const [nextPage, setNextPage] = useState(null);

  const [sorting, setSorting] = useState("");

  const [mainTableDataArray, setMainTableDataArray] = useState([]);

  const [createdAtStart, setCreatedAtStart] = useState("");

  const [createdAtEnd, setCreatedAtEnd] = useState("");

  const handleSelectedValuesChange = (newSelectedValues) => {
    dispatch(mapSelectedDecisions(newSelectedValues))
    localStorage.setItem(localStorageKeys.SELECTED_DECISIONS,JSON.stringify(newSelectedValues))
  };

  const handleDateRangeSelect = (startDate, endDate) => {
    switch (dateTypeFilter) {
      case DECISION_DATE:
        setCreatedAtStart(startDate);
        setCreatedAtEnd(endDate);
        break;
      default:
        setCreatedAtStart("");
        setCreatedAtEnd("");
    }
  };

  const handleClearFilter = () => {
    setSearch("")
    setCreatedAtStart("")
    setCreatedAtEnd("")
    setStatus("")
    setTriggerFilter("")
  }

  const paginationProps = {
    totalCount, setTotalCount, totalPage, setTotalPage, currentPage, setCurrentPage, resultsPerPage, setResultsPerPage,
    previousPage, setPreviousPage, nextPage, setNextPage, resultsPerPageList, defaultResultsPerPage
  }

  const handleSortType = (data) => {
    setSorting(data)
  }

  const updateMainTableDataArray = (decisions) => {
    const data = decisions.map((decision) => ({
      unique_id: decision.id,
      decision_id: decision.decision_id,
      record_id: decision.record_id,
      logs: decision.logs_info,
    }));
    setMainTableDataArray(data);
  };

  const fetchData = () => {
    setLoader(true)
    service.get(`${apiendpoints?.DECISION_RUN_LOGS}/${search}?&page=${currentPage}&per_page_items=${resultsPerPage}`).then((res) => {
      const { content, content: { count, current_page, next_page, previous_page, total_pages } } = res
      setContent(content);
      setLoader(false)
      setError(false)
      setTotalCount(count)
      setNextPage(next_page)
      setPreviousPage(previous_page)
      setTotalPage(total_pages)
      setCurrentPage(current_page)

      if (content?.logs) {
        updateMainTableDataArray(content?.logs);
      }
    }).catch((error) => {
      setError(true)
      setLoader(false)
    });
  }

  useEffect(() => {
    const allDecisionIds = selectedDecisions && selectedDecisions?.flatMap(
        (selectedValue) => selectedValue.decision_ids
    );
    setSelectedDecisionIds(allDecisionIds);
}, [selectedDecisions]);


  useEffect(() => {
    if(search){
      fetchData()
    }
  }, [search, sorting, resultsPerPage, currentPage, status, createdAtStart, createdAtEnd, triggerFilter])


  const updateHeight = () => {
    if (document.getElementById('getHeightApp')) {
      const newHeight = document.getElementById('getHeightApp').offsetHeight;
      setHeight(newHeight);
    }
  };

  setTimeout(() => {
    updateHeight();
  }, 0);

  useEffect(() => {
    window.scrollTo(0, 0);
    updateHeight();

  }, [location.pathname, showFilter]);

  useEffect(() => {
    const paddingTop = document.getElementById('paddingTopAppDec');
    if (paddingTop) {
      paddingTop.style.padding = `${height - 35}px 0 0 0`;
    }
  }, [height, showFilter]);

  return (
    <Box id={'paddingTopAppDec'} pt={{ base: "130px", md: "156px", xl: "156px" }}>
      <FixedHeaderTop
      showFilter={showFilter} setShowFilter={setShowFilter}
      setSelectedDecisionIds={setSelectedDecisionIds} selectedDecisionIds={selectedDecisionIds} selectedValues={selectedValues} setSelectedValues={setSelectedValues} setDecisionSearch={setSearch} decisionSearch={search} triggerFilter={triggerFilter} setTriggerFilter={setTriggerFilter}
        onDateRangeSelect={handleDateRangeSelect} selectedOption={status} setSelectedOption={setStatus} handleClearFilter={handleClearFilter} />
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
        {loader ? <LazyLoader view="table" /> :
          error ? <SomethingWrong /> :
            content && content.count == 0 ? <NoResults /> :
              <>
                <MainTable setSelectedDecisionIds={setSelectedDecisionIds} selectedDecisionIds={selectedDecisionIds}  setSorting={setSorting} selectedValues={selectedValues} setSelectedValues={setSelectedValues} handleSelectedValuesChange={handleSelectedValuesChange} columnsData={columnsDataComplex} tableData={mainTableDataArray} handleSortType={handleSortType} />
                <Flex justify="flex-end">
                  <Pagination paginationProps={paginationProps} />
                </Flex>
              </>
        }
      </SimpleGrid>
    </Box>
  );
}
