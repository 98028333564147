import React from "react";
import { Icon, Flex, Image } from "@chakra-ui/react";
import { MdArticle, MdSettingsBackupRestore, MdPerson2,MdViewTimeline, MdCurrencyExchange, MdOutlineCases, MdOutlineMonetizationOn, MdListAlt, MdCircle } from "react-icons/md";
import { HiSquare2Stack } from "react-icons/hi2";
import { PiScrollBold } from "react-icons/pi";
import { TbFileDollar } from "react-icons/tb";
import Applications from "views/admin/applications";
import Businesses from "views/admin/businesses";
import DecisionRuns from "views/admin/decisionRuns";
import DataTables from "views/admin/decisionSummary";
import Persons from "views/admin/persons";
import PlaidTransactions from "views/admin/plaidTransactions";
import BalanceSheet from "views/admin/balanceSheet";
import Invoices from "views/admin/invoices";
import ProfitLossStatement from "views/admin/profit&lossStatement";
import SignIn from "views/auth/signIn";
import Maintenance from "views/admin/maintenance";
import DecisionBalanceSheet from "views/admin/decisionsFolder/accounting/balanceSheet"
import DecisionInvoices from "views/admin/decisionsFolder/accounting/invoices"
import DecisionProfitLossStatement from "views/admin/decisionsFolder/accounting/profit&lossStatement"
import DecisionTransaction from "views/admin/decisionsFolder/transactions"
import DecisionRunLogs from "views/admin/decisionRunLogs";

export const routes = [
  {
    name: "Applications",
    headerName: "",
    layout: "",
    path: "/applications",
    icon: <Flex align="center"><Icon as={MdArticle} width='20px' height='20px' color='inherit' /></Flex>,
    component: Applications,
  },
  {
    name: "Businesses",
    headerName: "",
    layout: "",
    path: "/businesses",
    icon: <Flex align="center"><Icon as={MdOutlineCases} width='20px' height='20px' color='inherit' /></Flex>,
    component: Businesses,
    secondary: true,
  },
  {
    name: "Persons",
    headerName: "",
    layout: "",
    path: "/persons",
    icon: <Flex align="center"><Icon as={MdPerson2} width="20px" height="20px" color="inherit" /></Flex>,
    component: Persons,
  },
  {
    name: "Decision Runs",
    headerName: "",
    layout: "",
    path: "/decision-runs",
    icon: <Flex align="center"><Icon as={MdSettingsBackupRestore} width="20px" height="20px" color="inherit" transform={'rotate(45deg)'} /></Flex>,
    component: DecisionRuns,
  },
  {
    name: "Decision Run Logs",
    headerName: "",
    layout: "",
    path: "/decision-run-logs",
    icon: <Flex align="center"><Icon as={MdViewTimeline} width="20px" height="20px" color="inherit"/></Flex>,
    component: DecisionRunLogs,
  },
];


export const applicationRoutes = [
  {
    name: "Banking Transactions",
    headerName: "",
    layout: "/application",
    path: "/plaid",
    icon: <Flex align="center"><Icon as={HiSquare2Stack} width="20px" height="20px" color="inherit" /></Flex>,
    component: PlaidTransactions,
    items:[],
  },
  {
    name: "Accounting",
    headerName: "Accounting",
    layout: "/application",
    path: "/accounting",
    icon: <Flex align="center"><Icon as={MdOutlineMonetizationOn} width="20px" height="20px" color="inherit" /></Flex>,
    items: [
      {
        name: "Balance Sheet",
        headerName: (
          <>
            <span style={{ color: "black" }}> Accounting |</span> Balance Sheet
          </>
        ),
        layout: "/application",
        path: "/accounting/balance-sheet",
        component: BalanceSheet,
        icon: <Flex align="center"><Icon as={MdCircle} width="4px" height="4px" color="inherit" /></Flex>,
      },
      {
        name: "P&L Statement",
        headerName: (
          <>
            <span style={{ color: "black" }}> Accounting |</span> P&L Statement
          </>
        ),
        layout: "/application",
        path: "/accounting/pl-statement",
        component: ProfitLossStatement,
        icon: <Flex align="center"><Icon as={MdCircle} width="4px" height="4px" color="inherit" /></Flex>,
      },
      {
        name: "Invoices",
        headerName: (
          <>
            <span style={{ color: "black" }}> Accounting |</span> Invoices
          </>
        ),
        layout: "/application",
        path: "/accounting/invoices",
        component: Invoices,
        icon: <Flex align="center"><Icon as={MdCircle} width="4px" height="4px" color="inherit" /></Flex>,
      },
    ],

  },
  {
    name: "Financial Statements",
    headerName: "Financial Statements",
    layout: "/application",
    path: "/financial-statement",
    icon: <Flex align="center"><Icon as={MdListAlt} width="20px" height="20px" color="inherit" /></Flex>,
    component: Maintenance,
    items: [],
  },
  {
    name: "Contracts",
    headerName: "Contracts",
    layout: "/application",
    path: "/contracts",
    icon: <Flex align="center"><Icon as={TbFileDollar} width="20px" height="20px" color="inherit" /></Flex>,
    component: Maintenance,
    items: [],
  },
  {
    name: "Subscriptions/Billing",
    headerName: "",
    layout: "/application",
    path: "/billing",
    icon: <Flex align="center"><Icon as={PiScrollBold} width="20px" height="20px" color="inherit" transform={'scaleX(-1)'} /></Flex>,
    component: Maintenance,
    items: [],
  },
  {
    name: "Decision Runs",
    headerName: "",
    layout: "/decision",
    path: "/summary?type=plaid",
    icon: <Flex align="center"><Icon as={MdSettingsBackupRestore} width="20px" height="20px" color="inherit" transform={'rotate(45deg)'} /></Flex>,
  },
];

export const decisionRunRoutes = [
  {
    name: "Summary",
    headerName: "Summary",
    layout: "/decision",
    path: "/summary",
    component: DataTables,
    icon: <Flex align="center"><Icon as={MdSettingsBackupRestore} width="20px" height="20px" color="inherit" transform={'rotate(45deg)'} /></Flex>,
    items: [],
  },
  {
    name: "Banking Transactions",
    headerName: "",
    layout: "/decision",
    path: "/transaction",
    icon: <Flex align="center"><Icon as={HiSquare2Stack} width="20px" height="20px" color="inherit" /></Flex>,
    component: DecisionTransaction,
    items: [],
  },
  {
    name: "Accounting",
    headerName: "Accounting",
    layout: "/decision",
    path: "/accounting",
    icon: <Flex align="center"><Icon as={MdOutlineMonetizationOn} width="20px" height="20px" color="inherit" /></Flex>,
    items: [
      {
        name: "Balance Sheet",
        headerName: (
          <>
            <span style={{ color: "black" }}> Accounting |</span> Balance Sheet
          </>
        ),
        layout: "/decision",
        path: "/accounting/balance-sheet",
        component: DecisionBalanceSheet,
        icon: <Flex align="center"><Icon as={MdCircle} width="4px" height="4px" color="inherit" /></Flex>,
      },
      {
        name: "P&L Statement",
        headerName: (
          <>
            <span style={{ color: "black" }}> Accounting |</span> P&L Statement
          </>
        ),
        layout: "/decision",
        path: "/accounting/pl-statement",
        component: DecisionProfitLossStatement,
        icon: <Flex align="center"><Icon as={MdCircle} width="4px" height="4px" color="inherit" /></Flex>,
      },
      {
        name: "Invoices",
        headerName: (
          <>
            <span style={{ color: "black" }}> Accounting |</span> Invoices
          </>
        ),
        layout: "/decision",
        path: "/accounting/invoices",
        component: DecisionInvoices,
        icon: <Flex align="center"><Icon as={MdCircle} width="4px" height="4px" color="inherit" /></Flex>,
      },
    ],

  }
];


