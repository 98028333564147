export const columnsDataComplex = [
  {
    Header: "",
    accessor: "unique_id",
  },
  {
    Header: "Decision ID",
    accessor: "decision_id",
  },
  // {
  //   Header: "Application ID",
  //   accessor: "record_id",
  // },
  {
    Header: "Logs",
    accessor: "logs",
  }
];
